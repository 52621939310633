import styled from '@emotion/styled'
import React, { memo } from 'react'
import { Helmet } from 'react-helmet'

export default memo(function HomePageTemplate() {
  return (
    <>
      <Helmet>
        <meta
          name="facebook-domain-verification"
          content="y2t1bofamg0su4r04jcu8y6xq8g5ht"
        />
      </Helmet>
      <Container>
        <Wrapper>
          <Title>Zucchetti Hospitality</Title>
        </Wrapper>
      </Container>
    </>
  )
})

const Container = styled.main`
  background: ${({ theme }) => theme.colors.variants.primaryLight1};
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
`

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);

  @media (max-width: 767px) {
    width: 90%;
  }
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3rem;
  font-weight: 600;
  line-height: 4rem;
`
